import React, { useEffect, useRef } from "react";

function Dice(props) {
  const ref = useRef(null);
  useEffect(() => {
    function rollDice() {
      let dice = Math.floor(Math.random() * 6 + 1);
      for (let i = 1; i <= 6; i++) {
        ref.current.classList.remove("show-" + i);
        if (dice === i) {
          ref.current.classList.add("show-" + i);
        }
      }
      props.setRoll(false);
    }
    rollDice();
  }, [props]);

  return (
    <div className="wrapper-container">
      <div
        ref={ref}
        id="dice1"
        className="dice dice-one"
        style={{ left: props.left }}
      >
        <div id="dice-one-side-one" className="side one">
          <div className="dot one-1"></div>
        </div>
        <div id="dice-one-side-two" className="side two">
          <div className="dot two-1"></div>
          <div className="dot two-2"></div>
        </div>
        <div id="dice-one-side-three" className="side three">
          <div className="dot three-1"></div>
          <div className="dot three-2"></div>
          <div className="dot three-3"></div>
        </div>
        <div id="dice-one-side-four" className="side four">
          <div className="dot four-1"></div>
          <div className="dot four-2"></div>
          <div className="dot four-3"></div>
          <div className="dot four-4"></div>
        </div>
        <div id="dice-one-side-five" className="side five">
          <div className="dot five-1"></div>
          <div className="dot five-2"></div>
          <div className="dot five-3"></div>
          <div className="dot five-4"></div>
          <div className="dot five-5"></div>
        </div>
        <div id="dice-one-side-six" className="side six">
          <div className="dot six-1"></div>
          <div className="dot six-2"></div>
          <div className="dot six-3"></div>
          <div className="dot six-4"></div>
          <div className="dot six-5"></div>
          <div className="dot six-6"></div>
        </div>
      </div>
    </div>
  );
}

export default Dice;
