import React, { useState } from "react";
import Dice from "./Dice";
import PopupModal from "./PopupModal";

function DiceApp() {
  const [isRolling, setIsRolling] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [popupContent, setPopupContent] = useState({
    title: "Don't miss out !!",
    description: "Experience the full potential! Sign up now for exclusive features, handcrafted to amplify your journey with us. Don't settle for less."
  });

  const handleRollDice = () => {
    setIsRolling(true);


    setTimeout(() => {
      setIsRolling(false);
      setShowPopup(true);
    }, 2000); 
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setShowEmailInput(false);
  };

  const handleYesClick = () => {
    setShowEmailInput(true);
    setPopupContent({
      title: "YYYaaayyyy !!!",
      description: "Thank you for signing up! Enjoy exclusive features and more."
    });
  };

  const handleNoClick = () => {
    setShowPopup(false);
    setShowEmailInput(false);
  };

  return (
    <>
      <div className="text-center">
        <h3 className="h2 fw-bold mb-3 mt-4">Roll The Dice</h3>
      </div>
      <br />

      <div className="container-wrap">
        <div className="container">
          <div className="row" style={{ display: "flex" }}>
          
            <div className="col-lg-12 col-xl-12">
              <div className="p-5 rounded-3" style={{ backgroundColor: "rgb(255, 255, 255)", height: "500px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
                <div className="" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <h3 style={{ fontFamily: "'Comic Sans MS', 'Chalkboard SE', 'Comic Neue', sans-serif", color: "#000000", fontSize: "25px" }}>
                    Rolling the dice: where chance meets destiny.
                  </h3>
                  <Dice
                    className="dice1"
                    isRolling={isRolling}
                    setRoll={setIsRolling}
                    left={-175}
                  />
                  <Dice
                    className="dice2"
                    isRolling={isRolling}
                    setRoll={setIsRolling}
                    left={100}
                  />
                  <div id="roll" className="roll-button" style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                    <button className="button fw-normal" type="button" style={{ borderRadius: 3 }} onClick={handleRollDice} disabled={isRolling}>
                      {isRolling ? "Rolling..." : "Roll dice!"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    
      {showPopup && (
        <PopupModal
          onClose={handleClosePopup}
          onYesClick={handleYesClick}
          onNoClick={handleNoClick}
          showEmailInput={showEmailInput}
          popupContent={popupContent}
        />
      )}
    </>
  );
}

export default DiceApp;
